/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 78번째 로그에서는 Meetup 위워크에서 독립, AWS 람다 삽질기, 클라우드 vs. 온프레미스 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "Meetup.com, WeWork에서 독립"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/"
  }, "We are what we do | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.chosun.com/site/data/html_dir/2017/11/29/2017112902238.html"
  }, "'사무실 공유' 위워크, 모임 사이트 ‘미트업’ 2억달러에 인수 - 조선닷컴 - 국제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/blog/the-next-chapter-of-meetup/"
  }, "The Next Chapter of Meetup - Meetup Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.chosun.com/site/data/html_dir/2020/03/23/2020032304420.html"
  }, "소프트뱅크, 51조원 자산 매각 나서 - 조선닷컴 - 경제 > 산업·재계")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/awskrug/"
  }, "AWSKRUG - AWS한국사용자모임 (Seoul, Korea (South)) | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/"
  }, "Festa! 이벤트를 만나는 가장 쉬운 방법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meetgo.kr/"
  }, "Meetgo :: 오프라인 모임 플랫폼 밋고")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/10/15/20893343/meetup-users-furious-new-rsvp-payment-test"
  }, "Meetup wants to charge users $2 just to RSVP for events — and some are furious - The Verge")), "\n"), "\n", React.createElement(_components.h3, null, "너굴 님의 AWS 람다 삽질기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/Miserlou/Zappa"
  }, "Miserlou/Zappa: Serverless Python")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/lambda/latest/dg/lambda-intro-execution-role.html"
  }, "AWS Lambda 실행 역할 - AWS Lambda")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/proxy/"
  }, "Amazon RDS Proxy - Amazon Web Services")), "\n", React.createElement(_components.li, null, "[Amazon RDS Proxy with PostgreSQL Compatibility (Preview)](ing to slower performance and limited application scalability. RDS Proxy sits between your application and its dat)"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://serverless.com/"
  }, "Serverless - The Serverless Application Framework powered by AWS Lambda, API Gateway, and more")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://romandc.com/zappa-django-guide/setup/"
  }, "Setup your Environment - Guide to using Django with Zappa")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/fargate/"
  }, "AWS Fargate – 서버 또는 클러스터를 관리할 필요 없이 컨테이너를 실행")), "\n"), "\n", React.createElement(_components.h3, null, "클라우드 vs. 온프레미스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.etnews.com/20200317000207"
  }, "삼성, 자체 클라우드 키운다 - 전자신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/news/148441"
  }, "'전진 위한 후퇴'··· 클라우드서 온프레미스로 송환하는 기업들 - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/375005"
  }, "네오위즈, 자체 데이터센터 폐쇄...AWS 클라우드 올인 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datacenterdynamics.com/en/news/zynga-abandons-data-centers-returns-to-aws/"
  }, "Zynga abandons data centers, returns to AWS - DCD")), "\n"), "\n", React.createElement(_components.h3, null, "분당 부동산 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://m.joongdo.co.kr/view.php?key=20200214010005724"
  }, "성남시, 공유재산 매각 벤처 우수기업 유치 성공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://m.bundangnews.co.kr/27936"
  }, "분당신문 - 성남시, 분당구 삼평동 641번지 시유지 매각조건 완화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.midasit.com/"
  }, "마이다스아이티 - 글로벌 엔지니어링 솔루션 개발 및 서비스 파트너")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.midashri.com/intro/ai"
  }, "inAIR(인에어) | AI 역량검사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hf.go.kr/hf/sub01/sub01_01_01.do"
  }, "주택금융공사 | 주택담보대출 | 디딤돌대출 | 상품소개 | 소개")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
